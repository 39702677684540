import React from "react";
import { LuPackageOpen } from "react-icons/lu";
import { IconContext } from "react-icons";

const Paso4Home = () => {
  return (
    <>
      <IconContext.Provider
        value={{ color: "red", className: "global-class-name", size: "6em" }}
      >
        <div className=" home-jumbotron d-flex flex-column text-center justify-content-center align-items-center ">
          <LuPackageOpen className="mt-5" />
          <div>
            <p className="fs-2">RECIBE</p>
            <p className="fs-5">
              ¡Recibe tu paquete! <br />
              Puedes recogerlo en nuestra dirección: Ajalpan 16 Villas
              Atlixcayotl. Puebla <br />
              También puedes elegir uno de nuestros servicios a domicilio
            </p>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Paso4Home;
