import React from "react";
import { BsFillClipboardCheckFill } from "react-icons/bs";
import { IconContext } from "react-icons";

const Paso1Home = () => {
  return (
    <>
      <IconContext.Provider
        value={{ color: "red", className: "global-class-name", size: "6em" }}
      >
        <div className=" home-jumbotron d-flex flex-column text-center justify-content-center align-items-center ">
          <BsFillClipboardCheckFill className="mt-5" />
          <div>
            <p className="fs-2">REGISTRATE</p>
            <p className="fs-5">
              Manda un whatsapp o mensaje de texto al numero: +52 221 652 4006{" "}
              <br />
              ¡Con gusto uno de nuestros representantes creará tu perfil para
              poder comenzar con tus pedidos!
            </p>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Paso1Home;
