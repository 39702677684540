import React from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { IconContext } from "react-icons";

const Paso2Home = () => {
  return (
    <>
      <IconContext.Provider
        value={{ color: "red", className: "global-class-name", size: "6em" }}
      >
        <div className="home-jumbotron d-flex flex-column text-center justify-content-center align-items-center">
          <AiOutlineShoppingCart className="mt-5" />
          <div>
            <p className="fs-2">REALIZA TU COMPRA</p>
            <ul className="fs-5">
              <li>Ingresa a tu tienda favorita</li>
              <li>Escoge tus productos</li>
              <li>Al finalizar, ingresa nuestra dirección de envío</li>
            </ul>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
};

export default Paso2Home;
