import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Faq from "./pages/Faq";
import Paso1Home from "./pages/Paso1Home";
import Paso2Home from "./pages/Paso2Home";
import Paso3Home from "./pages/Paso3Home";
import Paso4Home from "./pages/Paso4Home";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />}>
              <Route path="home/step1" element={<Paso1Home />} />
              <Route path="home/step2" element={<Paso2Home />} />
              <Route path="home/step3" element={<Paso3Home />} />
              <Route path="home/step4" element={<Paso4Home />} />
            </Route>
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/FAQ" element={<Faq />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
